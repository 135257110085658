import React from 'react';
import 'whatwg-fetch';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Header from '../../../components/Header/navmap/landingFleet';
import sitemap from '../../../data/sitemap';
import i18n from '../../../locale';
import Contact from './Contact';
import Done from './Done';
import styles from './styles.module.sass';

const postToForm = data => {
  return fetch(
'https://api.hsforms.com/submissions/v3/integration/submit/5142926/a6143a7a-3c46-4f81-81c4-596ec542378b',
{
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    'submittedAt': Date.now(),
    'fields': [
      {
        'name' : 'email',
        'value': data.email
      }, {
        'name' : 'phone',
        'value': data.phone ? data.phone : ''
      }, {
        'name': 'firstname',
        'value': data.name ? data.name : ''
      }, {
        'name': 'regNumber',
        'value': data.regNumber ? data.regNumber : ''
      }, {
        'name' : 'vehicle_type_cars',
        'value': data['vehicle-types'].cars
      }, {
        'name' : 'vehicle_type_vans',
        'value': data['vehicle-types'].vans
      }, {
        'name' : 'vehicle_type_trucks',
        'value': data['vehicle-types'].trucks
      }, {
        'name' : 'vehicle_type_trailers',
        'value': data['vehicle-types'].trailers
      }, {
        'name' : 'garrage',
        'value': data.garage ? data.garage.value : ''
      }, {
        'name' : 'number_of_vehicles',
        'value': data['number-of-vehicles'] || 1
      }
    ],
    'context': {
      'pageUri': i18n('_url:_') + i18n('_url:landing-fleet-demo'),
      'pageName': 'Fleet Landing page request a demo'
    },
    'legalConsentOptions': {
      "consent": {
        "consentToProcess": true,
        "text": "I agree to allow OBI+ to store and process my personal data.",
        "communications": [
          {
            "value": true,
            "subscriptionTypeId": 999,
            "text": "I agree to receive marketing communications from OBI+."
          }
        ]
      }
    }
  })
})
}

export default ({ seo, location }) => {
  const [ error, setError ] = React.useState(false);
  const [ step, setStep ] = React.useState(0);
  const [ formData, setFormData ] = React.useState({
    'vehicle-types': {
      'cars': true,
      'vans': false,
      'trucks': false,
      'trailers': false
    }
  });
  // const onNext = () => setStep(step + 1);
  const setFormKey = key => data => setFormData({
    ...formData,
    [key]: data
  });

  return (
    <Layout overlay 
      page={sitemap.landingFleet.demo}
      header={Header}
      isLanding={'fleet'}
      url='_url:landing-fleet-demo'
      homeLink={i18n('_url:landing-fleet')}>
      <SEO {...seo} location={location}/>
      <div className={styles.container}>
        {step === 0
        ? <Contact
            email={formData['email']}
            phone={formData['phone']}
            name={formData['name']}
            error={error}
            regNumber={formData['regNumber']}
            setEmail={setFormKey('email')}
            setPhone={setFormKey('phone')}
            setName={setFormKey('name')}
            setRegNumber={setFormKey('regNumber')}
            onNext={() => postToForm(formData)
              .then(res => res.json()).then(res => {
                if(res.status !== 'error') {
                  setStep(step + 1);
                  setError(false);
                } else setError(true);
              }).catch(() => setError(true))}/>
        : <Done/>}
      </div>
    </Layout>
  )
}